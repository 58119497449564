import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Review Request Templates" prev={{
  name: 'Review Request Flow',
  path: '/review-request-flow'
}} next={{
  name: 'Custom Review Request Email Templates',
  path: '/custom-review-request-email-templates'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Templates are the layout and text a customer sees when they are sent a review request.`}</p>
    <p>{`There are templates for both emails and SMS text messages.`}</p>
    <p>{`For each review request type there are multiple templates for each contact method.`}</p>
    <p>{`The reason for there being multiple template variations for each contact method in a review request type is because we are constantly trying to improve the conversion rate of our review requests.`}</p>
    <p>{`One of the ways we try to achieve this is by A/B testing our templates. In this case A/B testing means a continuous process of retiring the lowest performing templates and introducing new templates to test.`}</p>
    <p>{`When a customer is being sent a review request a template is randomly selected based on the review request type and the contact method. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      